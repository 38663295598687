import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close } from "reactour";
import { Modal, ModalHeader, ModalBody, Input, Button, ModalFooter, Label } from "reactstrap";
import { useState } from "react";

const ContentModal = ({ open, onClose, content, fields, saveContent }) => {
  const [data, setData] = useState(content);
  const setContent = async () => {

  }

  const generate = async () => {
    onClose();
  }

  return (
    <Modal isOpen={open} toggle={onClose} size="lg">
      <ModalHeader>Generate Content <Close onClose={onClose} /> </ModalHeader>
      <ModalBody>
        {fields.map((field) => {
          return <>
            <Label key={field.fieldVariable} labelFor={field.fieldVariable}>{field.fieldDisplayName}
            </Label>
            <Input id="{field.fieldVariable}" type={field.type} value={data[field.fieldVariable]} onChange={(e) => setData({...data, [field.fieldVariable]: e.target.value})} placeholder={`Enter ${field.fieldDisplayName}`} className="mb-3" />
          </>
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => saveContent(data)}><FontAwesomeIcon icon={faSave} /> Save</Button>{' '}
        <Button color="secondary" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContentModal;