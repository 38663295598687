import React, { Component, Fragment } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Chart from "react-apexcharts";

import {
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";

import PageTitle from "../../../Layout/AppMain/PageTitle";

export default class MinimalDashboard2 extends Component {
  constructor(props) {
    super(props);


  }

  render() {
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true}
            timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading="Preview Dashboard"
                subheading="This is a sample dashboard."
                icon="lnr-apartment icon-gradient bg-mean-fruit" />
              <Card className="mb-3">
                <CardHeader className="tabs-lg-alternate">

                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    Sample Widgets
                  </div>
                </CardBody>
              </Card>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
